<template>
  <div>
    <v-text-field @input="onInput" v-bind="$attrs" v-on="$listeners" :value="value" :rules="rulesArray" />
  </div>
</template>

<script>
export default {
  name: "EmailField",
  props: {
    value: [Object, String],
    rules: Array,
    notRequired: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rulesArray: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    resetRulesArray() {
      this.rulesArray = [];
      if (!this.notRequired) {
        this.rulesArray.concat("Email field is required.");
      }
      if (this.rules && this.rules.length) {
        this.rulesArray = this.rulesArray.concat(this.rules);
      }
      this.rulesArray = this.rulesArray.concat(this.defaultValidations);
    },
    defaultValidations(value) {
      if (typeof value === "string" || value instanceof String) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regex.test(value)) {
          return "Valid E-mail is required.";
        }
        if (value.length > 100) {
          return "Email must be less than 100 characters.";
        }
        return true;
      }
      return true;
    }
  },
  mounted() {
    this.resetRulesArray();
  }
};
</script>
